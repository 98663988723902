<template>
  <div class="hello">
    <div id="header">
      <lottie-animation
        id="logo"
        ref="anim"
        @complete="complete"
        :animationData="require('@/assets/animation/data.json')"
      />
      <ogl-background
        class="hidden"
        :class="{ visible: completed }"
        :start="completed"
      />
      <!-- <img :src="logo" alt="Bureau Luthi" id="logo" /> -->
      <!-- <div id="image-grid">
        <div v-for="(image, index) in images" :key="index">
          <img
            :src="image.path"
            alt=""
            class="image"
            :style="
              'top: ' +
              image.top +
              '%; left:' +
              (image.left + image.offsetLeft) +
              '%;'
            "
          />
        </div>
      </div> -->
    </div>
    <div class="container" id="introduction">
      <div class="row">
        <div class="col-12">
          <p class="center">
            Bureau Lüthi is a Zurich based multi-disciplinary architecture,
            interior architecture and design practice and stands for „we exist
            to create“ and „we give meaning to space“. Demonstrating a
            commitment to design excellence, innovation and sustainability. We
            develop architecture in an ever-evolving process that continuously
            challenges the boundaries of the possible with international
            expertise.
          </p>
        </div>
      </div>
    </div>
    <div class="container-fluid text-row">
      <div class="row">
        <div class="col-12 col-xl-4">
          <div class="text-row-item">
            <img src="../assets/line.svg" alt="" width="48" height="48" />
            <div>
              <h2>Architecture</h2>
              <p>
                Innovative, social, green and livable architecture for a
                changing world. We aim for intelligent, evocative design within
                real-world constraints that is enriching peoples lives in an
                around our buildings.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="text-row-item">
            <img src="../assets/line.svg" alt="" width="48" height="48" />
            <div>
              <h2>Future of Retail</h2>
              <p>
                Together with our clients we thrive to create the future of
                retail. Designing unique spatial environments by integrating the
                latest technology to embrace customers into fully immersive
                experience.
              </p>
              <p>
                Driven by a deeply held belief in the transformative power of
                well designed, finely crafted spaces, we create retail
                environments with a bespoke identity.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="text-row-item">
            <img src="../assets/line.svg" alt="" width="48" height="48" />
            <div>
              <h2>Interior Design</h2>
              <p>
                We create spaces for and with people. An uncompromising
                dedication to quality and craftsmanship for livable and
                experimental spaces with a holistic approach.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import lottie from "lottie-web";
import LottieAnimation from "lottie-web-vue"; // import lottie-web-vue
import OglBackground from "../components/oglBackground.vue";

export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      completed: false,
    };
  },
  components: {
    LottieAnimation,
    OglBackground,
  },
  methods: {
    complete() {
      this.completed = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hidden {
  opacity: 0;
  transition: all 1s ease-in-out;
}
.visible {
  opacity: 1;
}

#header {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 110vh;
  height: 800px;
  margin-bottom: 80px;

  // max-height: 800px;
  @media (min-width: 992px) {
    height: 1000px;
    margin-bottom: 120px;
  }

  #logo {
    background-color: transparent;
    z-index: 10;
    width: 60%;
    max-width: 420px;
    margin-bottom: 10vh;
  }

  #image-grid {
    position: absolute;
    height: inherit;
    width: 100%;
    // background-color: aquamarine;

    .image {
      position: absolute;
      width: 20%;
    }
  }
}

#introduction {
  padding: 0 5% 200px 5%;

  @media (min-width: 992px) {
    padding: 0 5% 240px 5%;
  }
}

.text-row {
  max-width: 1800px;
  padding: 0 4%;
  .text-row-item {
    padding: 0px 3rem;
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 80px;

    img {
      margin-right: 8px;
    }
  }
}
</style>
